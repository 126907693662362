import React from "react";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReelCastEpisodeTemplate from "./reelcastEpisodeTemplate";

class S03E10 extends React.Component {
    constructor(props) {
        super(props);
        ReactGA.pageview(window.location.pathname); 
    }
    
    componentDidMount() {
        const script = document.createElement("script");
        script.src = `https://www.buzzsprout.com/2057836/${this.props.id}.js?container_id=buzzsprout-player-${this.props.id}&player=small`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.body.appendChild(script);

        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }

    shouldComponentUpdate(nextProps) {
        return (
            this.props.title !== nextProps.title ||
            this.props.subTitle !== nextProps.subTitle ||
            this.props.description !== nextProps.description
        );
    }

    componentDidUpdate() {
        if (Helmet.canUseDOM) {
            Helmet.renderStatic();
        }
    }
    
    render() {
        return (
            <>
                <Helmet>
                    <title>{`${this.props.title} | ${this.props.subTitle} | ReelCast by ReelMetrics`}</title>

                    <meta
                        name="Description"
                        content={this.props.description}
                    />
                    <link
                        rel="canonical"
                        href="https://www.reelmetrics.com/s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau"
                    />

                    {/* Open Graph / Facebook / LinkedIn */}
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:url"
                        content="https://www.reelmetrics.com/s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau"
                    />
                    <meta property="og:title" content="ReelCast - S03E10" />
                    <meta
                        property="og:description"
                        content={this.props.description}
                    />
                    <meta
                        property="og:image"
                        content="https://cdn.buttercms.com/uvl2RfnQQFOybc2bNujq"
                    />
                    <meta property="og:locale" content="en_US" />
                
                </Helmet>
                <ReelCastEpisodeTemplate {...this.props} />
            </>
        );
    }
}

    S03E10.defaultProps = {
        id: "16012692-s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau",
        link: "https://www.reelmetrics.com/s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau",
        title: "Konami Gaming with Jay Bertsch, Chris Rowe, and Stephanie Lau",
        subTitle: "Season 3, Episode 10",
        description: "In this episode, Nick and Don catch-up with Konami’s Jay Bertsch (SVP & Chief Commercial Officer), Chris Rowe (VP of North American Game Sales), and Stephanie Lau (VP of Sales Enablement). Hear the team’s thoughts on G2E ’24, as well as upcoming rollouts in systems, cabinets, and games. Also in this episode, Aristocrat, Light & Wonder, and the Dragon Train dispute.",
        topics: [
            "Konami's strategy and product highlights at G2E",
            "Konami's SYNKROS system and facial recognition technology",
            "Konami's approach to game development and market research",
            "Discussion on Konami's cabinet longevity and obsolescence strategy",
            "Konami's pricing strategy and value proposition",
            "Trends in slot game mechanics and player engagement"
        ],        
        keywords: [
            "Konami Gaming",
            "Jay Bertsch",
            "Chris Rowe",
            "Stephanie Lau",
            "G2E",
            "SYNKROS",
            "facial recognition",
            "slot game development",
            "gaming industry trends"
        ],    
    buzzSproutLink:
        "https://www.buzzsprout.com/2057836/16012692-s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau",
    spotifyLink:
        "https://open.spotify.com/show/314iBnXRGGAuttAsIcZeC5",
    applePodcastsLink:
        "https://podcasts.apple.com/us/podcast/reelcast-by-reelmetrics/id1652485625",
    iHeartRadioLink:
        "https://iheart.com/podcast/104029260/",
    cdcLink:
        "https://cdcgaming.com/reelmetrics/s03e10-konami-gaming-with-jay-bertsch-chris-rowe-and-stephanie-lau",
    
    transcript: `Nick Hogan:
Morning, Don. How are things in Southern Illinois this morning?
Don Retzlaff:
It's great, beautiful fall day. How's things in Netherlands?
Nick Hogan:
Gray, cold and wet. This is on par for standard late October. So, bummer, but so be it. So Don, another G2E in the tank, and we'll get to the highlights after we hit this month's big news item, which was without question the show's central conversation piece. And that was the legal dispute engulfing Aristocrat and Light & Wonder. Now for anybody who missed this. In Q1 of this year, Aristocrat filed suit against Light & Wonder alleging trade secret misappropriation and copyright infringement related to L&W's Dragon Train product.
So during the course of that discovery, it was determined that a L&W employee who'd formerly worked for Aristocrat, had brought with her and used elements of Aristocrat mathematical modeling in designing Dragon Train. So then in late September, about two weeks prior to G2E, the US District Court for the District of Nevada granted Aristocrat a preliminary injunction on the trade secret portion. The ruling barred L&W from, "Any continued or planned sale, leasing or other commercialization of Dragon Train."
So this kicked off pretty much a mad scramble at L&W, which in addition to being forced to scrap Dragon Train's big G2E launch, had to convert or swap by late October roughly 2,200 Dragon Train units. So without question, pretty much the definition of a four-alarm fire. As might be expected, absolutely everybody at G2E was talking about this, and comments really range from tip of the iceberg all the way to tempest in a teapot. And truly, Don, when I rewind these conversations and consider the degree of substantiation in them, I have to say that I'm more of the latter view, and feel that this will likely blow over with fairly limited impact.
Now, naturally I haven't spoken with a single Aristocrat or L&W employee about this matter, but I have spoken with a few independent IP attorneys, as well as a few financial folks who have, let's just say, highly material reasons to get to the bottom of this. So of the substantiated stuff, here is what I gleaned thumbnail. So first, as I understand it, L&W actually runs a very tight ship on the IP front. Apparently, upon starting with L&W, designers must pledge that they're not bringing with them any third-party IP, and that they'll not under any circumstances utilize third-party IP in developing products at L&W.
Second, again, as I understand it, the employee in question who's since been terminated, worked on no product other than Dragon Train. And that this boils down to an isolated and extensively contained case of employee misconduct and malpractice. And third, my understanding is that Aristocrat's copyright claims have been dismissed, and that punitive damages are not applicable in trade secret misappropriation disputes. So in all likelihood, financial damages will be limited to a value roughly approximating whatever it is that Aristocrat ultimately quantifies. Now, could all this be wrong? Sure. I have zero in the way of inside knowledge, and at the end of the day, I too, am just working with hearsay and speculation.
However, in terms of passing the smell test, I guess what I just ran down really struck me as the most plausible version. So assuming this stuff is correct, longer term, I guess my biggest concern here is the internal reaction formation at L&W. Often, such disputes result in procedural over-corrections and whatnot that constrain creativity, and just otherwise gum up the works. And time will certainly tell on that one. So grand scheme, Aristocrat naturally has every right to be furious about this and to litigate it. And based upon what we've seen from the courts thus far, odds appear pretty good that Aristocrat will be compensated at levels deemed appropriate and fair under US law. So Don, any additional thoughts on this one?
Don Retzlaff:
Just that L&W has a nice library of lease games. They should be fine in the short term. I guess the key is going to be, see what happens like the second quarter of next year after this run of replacements runs its course.
Nick Hogan:
Yeah.
Don Retzlaff:
See what they have in the replacement cycle coming up in 2025. That'll be the key for them.
Nick Hogan:
Yeah, I agree with you. Okay, cool. Well, we can move on from there into our listener question this month. Before I read it, let me just say we love to tackle any questions that anybody listening may have. If you have a question about what we're presenting or something you'd like us to present, please drop us an email at reelcast@reelmetrics.com. Again, that's reelcast@reelmetrics.com. Our policy is to keep all questions anonymous, so please speak directly, and don't worry about us revealing your identity. That's not something we do. So Don, from an operator on the Las Vegas Strip, we have, "Hi guys, love the show. I'm very curious to hear about anything that caught your eye at this year's G2E." So Don, that is smack-dab in the middle of your wheelhouse.
Don Retzlaff:
This is my 26th G2E, so I tend to look at G2E as a little bit differently knowing that 75% of the things that we see out there aren't going to work in real life on the casino floors. So I'm looking for trends, talking to people, trying to get ideas. On the ideas and people front, it was so encouraging to talk to the operators out there that are taking next level ideas to their slot floor. You talk to people like Michael DeJong at Wynn, and some of the ideas that he has on how to position machines. You talk to people like Dan Cherry, and Mike Babinski, and Michael Carruthers about their ideas. And it's just an education being out there. Still being in this business for 33 years, I'm still learning stuff, and it's just so good to see.
On the product side, I was afraid there'd be a lot of three pot perceived persistence games. I was pleasantly surprised that it wasn't. I liked some of the takes on the three pot games, some of the spin-offs. Saw some neat stuff from some of the smaller vendors, some neat stuff from AGS. But I guess one of my favorite games ended up coming up from Konami. And I got to play it on the floor at The Cosmopolitan, was Bingo Frenzy. I was a little worried about it just because past Bingo games didn't work so well. But this game actually worked. I liked that. I like the take-off on some of their three pot themes. But almost everybody had really solid cabinets. They look great now. Some of the interior design stuff is amazing. So a very good show, very busy. Busy than it has been for a couple of years. But I really enjoyed it. I thought everybody did a great job this year.
Nick Hogan:
It was an interesting... I caught Buddy Frank' editorial about the show, and I think he called it, "A normal, very great G2E." And he was referring to lack of over-the-top hyperbole this year, which I thought was indeed... I felt that so much what I was seeing out there was so much more grounded. There wasn't so much pie-in-the-sky of functionality, conjecture, et cetera. So yeah, I loved the show as well. Don't have the same type of product picks that you do, but so be it. So, Don, time to introduce today's guest. And this is a milestone, in that, we have three people with us today. So these folks have a combined 57 years of gaming experience spanning casino operations, supply, five continents and marquee organizations such as Stations Casinos, MGM Resorts, Aristocrat, Bally Technologies, and Konami Gaming.
As for the collegiate sports teams, Don, we have a Sun Devil and two Runnin' Rebels with us today. These days, these folks are serving in executive and senior managerial roles at Konami Gaming. I'm referring to SVP and chief commercial officer, Jay Bertsch, VP of North American Game Sales, Chris Rowe. And our data superstar, Don, VP of Sales Enablement, Stephanie Lau. So hello everybody. Thanks for joining us today.
Speaker X:
Thank you.
Stephanie Lau:
[inaudible 00:09:41]
Nick Hogan:
You bet. So I thought we should start here with a few expanded bios, just a quick summary of how you broke into the industry and your paths to your respective roles. So Stephanie, would you like to lead us off on this?
Stephanie Lau:
Sure. I started in gaming in 2005 actually in accounting, and I was at The Mirage before obviously that's been bought out since. And moved up the ranks pretty quickly, and was part of the opening team of when City Center opened. So had a blast opening up Aria, Vdara and all the casinos on there. And that path actually took me to Green Valley Ranch to work for Stations Casinos as their assistant financial control... No, financial controller at that time. And I realized my passion was more in finance and more than so in accounting. So I actually took on a role as the director of operations analysis at Stations Casinos for their corporate offices. So I ran all their analytics for a good six years, finally realizing my true passion was in slots and gaming. So that being said, it was like, you know what? Slot's one most interesting aspects of the world where you're asking people to give you money for entertainment, right?
Nick Hogan:
Yeah.
Stephanie Lau:
With unlimited spend, basically, right? So you don't know how much people will play out of their wallet and how much you pull out of the ATM. And seeing that through, I was at Stations digging in through player data, trying to figure out what works on the floor, how to yield banks better. And that because, again, capital's expensive. That being said, I want to optimize the floor as quickly as possible. So we spent a lot of time digging through PAR Sheets, looking at math models and seeing what works best for the Las Vegas local markets. And obviously the other markets that we've had previously at Stations helped manage Graton and Gun Lake as well. So it was fun digging through all that data as well. And then that brought me here, whereas Jay's like, "Hey, why don't you come on and lead our analytics and product team over here?" So I was like, "Well, that's a fun challenge. Why not, right?" So was very appreciative that Jay gave me this opportunity, and having a blast now.
Nick Hogan:
Okay, great. Thanks. And yeah, having seen some Stations' analytics from the outside, I can attest that you guys did a very good job over there. Very good analytics house, and I've always admired a lot of the modeling and whatnot that they've shared with me and I've seen over the years. So cool. Okay, then Chris, you want to do your background here?
Chris Rowe:
Sure. Thanks, Nick. And just one for the record. I would consider myself an honorary rebel. I spend a lot of time with UNLV alums, but happened to be a Utah Ute by-
Nick Hogan:
Oh, I'm sorry, man, I thought you were a UNLV guy. This is second time I screwed this up.
Chris Rowe:
These things are important, right? They have a lot to do with [inaudible 00:12:40]. But look, I started with Aristocrat in 2004 as an intern in sales and marketing. Spent nine years in product management, spent a lot of time on the tech side of product management looking after platforms and roadmaps. Did a pivot back to the commercial side of the business in 2015 with a move to Macau as the sales director for Asia Pacific. Looked after the sales team, big emerging markets at the time, Philippines, Vietnam, Cambodia, Singapore, based out of Macau, which had some tech standard changes at the time. Really fun role. Ended up looking after the business over there for a period of time.
Came back to the US in 2020 to look after Latin America, and also did a stint in Europe, Middle East and Africa. So covering some of those continents that you mentioned earlier. And look, and then recently, suppose six weeks before G2E, what a time to join. Made the pivot to Konami Gaming here as looking after sales for North America.
Nick Hogan:
Okay, great. And Jay, you're the last up here.
Jay Bertsch:
All right. So I went to, like you said, I went to ASU, graduated in '97. Subsequently went into some of the dot-com tech side of things, and spent four or five years as a developer kind of work in the tech trade and work in the dot-com bubble. Subsequently moved over into sales and working for a, what we call a contract manufacturer or supply chain X company, calling on the gaming business. And so ended up taking over Nevada as a sales rep calling on gaming manufacturers.
Subsequently, that company that I was working for, we ended up being awarded Konami's manufacturing supply chain business. We ended up taking over all of their building of their system components, some of their supply chain efforts on the game side, as well as some of the financial services as well. So I called on Konami for a couple years, and then Steve Sutherland had... Obviously calling on him directly, asked me to join the company. And subsequently have been here now for I guess, 18, 19 years.
Don Retzlaff:
Wow.
Jay Bertsch:
So been a fun ride, but definitely glad that I spent a fair part of my early career working in the tech sector, learning a lot about the technology. And it's been a huge benefit for me personally, just knowing enough about the tech space to be able to then translate that both into games and systems as we've continued to try to evolve our product. So it's been a fun ride. But I am extremely excited about both Chris and Stephanie joining the team. It's been an awesome opportunity for us as a business to kind of evolve, and start looking at things much differently than we have in the past. And the two individuals next to me are going to be a huge piece of what we think our future here is at Konami. So just excited about where we're at.
Nick Hogan:
Okay, cool. Thank you to all three of you. Really appreciate. Okay, since we just wrapped it, I always like to get the perspective of suppliers on this stuff. And maybe this is for you, Chris, just kind of get your impressions of this year's G2E. And if you could walk us through your major product pushes, and how everything was received there.
Chris Rowe:
Nick, G2E was awesome this year. Had the benefit, again, of joining six weeks before. So got to sit through the push-up to G2E, and really ramp quickly on Konami's strategy as a corporate, as well as our product and our customer base. The show was about all of that. We had the opportunity to put the product on full display, and also welcome amazing customers from across the globe. I mean, it kicked off right at 10:00 AM sharp on Tuesday morning. Booth was packed. We had meetings back-to-back every day of the show. And we gave a prize to the guy doing the last demo at I think 3:15 PM on the Thursday after show [inaudible 00:17:07]
Nick Hogan:
What did he get? What'd you hand out?
Chris Rowe:
Oh, I think he got a toast and a shout-out in the booth wrap, but-
Nick Hogan:
Okay.
Chris Rowe:
It was great. I think first and foremost, really, really proud of our team. I got to hang out with some really great people for a few days, which was wildly refreshing, just the people on the booth. The energy that I saw on display around our product, the reception for the customers, so really, really proud of our team and those people I get to work with every day.
I'd give a shout-out to our systems folks first. I think our system, SYNKROS, did an amazing job at G2E. Might recognize our facial recognition software there, and the buzz that was generated around that particular product. Which is on beta in the market right now, and we have big plans for in the future. So I think there's a bit of chatter around the show and definitely around our booth with that technology. But the system just goes strength-to-strength, just its reliability, its stability, but then these new innovations like facial rec and all that that will bring.
And then from the game side, we had a three-pillar strategy at G2E this year, and it really came to life on the booth. When you walked in, right away, you saw a wall full of All Aboard. It's a classic theme from Konami. We'd consider it part of our fabric at this point, a part of our heritage. And we showed it on display in every cabinet that Konami makes, and it'll be available that way from January next year as for sale. And so we made a switch from premium into for sale with that product as it's run its course, and lived its life cycle, but continues to perform very, very strong in the market. So we made that pivot with All Aboard, but then we showed an amazing premium lineup, so that was pillar number two.
We showed Bingo Frenzy. Thanks for the shout-out there, Don. It is a fun game. Played it again last night, maybe play it too much. But it has a really, really fun feature, really innovative take on hold and spin. But the depth in that library is probably better than we've shown in a number of years, and I get the benefit of that, having just joined. But the lineup there with Bingo, we've got another really, really strong performer in Lian Lian Fa, which is available today. And then again, the depth in games like K-Pow! Pig and Buzzer that are coming early next year. So we showed depth in that portfolio. And then we walked our customers into the sale portion of our booth where we've got some real hits, some of which you've featured. Thank you.
But Lucky Honeycomb. We had Charms full on display, two games that are performing very, very well in the market today. And then we showed the depth there with follow-ups such as What The Duck, yeah, you heard that right. What The Duck. And also Falling Coins. So yeah, look, All Aboard pivot to sale, depth in our premium library. And then just a lot to offer in the for-sale space. Just huge support for our Dimension fleet that's out there, and chugging in the market at the moment.
Nick Hogan:
Okay, cool. Well, trust in a little bit here, Don is going to be hammering you about all of these products in all kinds of detail. Okay. And I guess, Jay, this one's for you. A little bit of just about Konami generally. So Konami Gaming is very unique in gaming, in that, it's a really a domain-focused subsidiary of this Japanese publishing and digital entertainment conglomerate. So can you walk us through that structure a little bit? Like how managerially, how it works, and whether you share resources and things like this? Just give us a sense of how that works.
Jay Bertsch:
Yeah, so we, I wouldn't necessarily say that we share resources with corporate, but there's some distinct structures that we leverage as part of the... obviously being part of the subsidiary. We have kind of call it three main studios that we utilize from the game side. Which I think is a really unique thing for us in the gaming space, given that there's obviously a tremendous amount of great people in the Australian market and specifically around the development there. Obviously our competitors do that as well. But what we call KAP, which is our Australia branch is one of our main studios out of Australia. We have obviously a studio here in the US, but then as we discussed the whole digital entertainment conglomerate, we also have a studio in Japan.
I think the unique thing about the Japan studio is, and we talk a lot about it during not only our pre-G2Es, but our G2Es meetings in general, is that I think it's a really unique leverage for us that we can leverage the Australia and the US studios. But having that ability to also leverage that Japan studio really gives us a unique position in the market. I think the interesting thing about what we call KGIA, which is our Japan group, is they don't necessarily have the opportunity like the rest of our studios to actually go in and walk into a property and play machines. And so a lot of the people that are developing those products, either have to, one, travel somewhere quite far to play a game, or they have to sit at their desks and watch YouTube and other ways of leveraging product.
And so obviously there's some great ability to leverage the digital entertainment side and the creativity of that department. But in the same breath, those folks that are dedicated to gaming out of Japan, they have to be very unique with respect to how they learn and develop products. And to be honest with you, they are probably one of the better studios that we have right now. A lot of the great products that Chris described and Steph will also highlight as we go through some of these questions. A lot of the product is coming from our Japan studio. That team continues to really innovate, but also somewhat follow some of the industry trends.
So I mean, it's a fun opportunity for us to be able to leverage those three different studios. We also do a little bit of outsourcing with respect to third-party studios. But there's no question that having that team out of Japan has been an incredible opportunity for us and has really bared a lot of the fruit that we're going to talk about today and have talked about during the show. So yeah, it's definitely a big leverage point for us.
Nick Hogan:
Okay. And now, Jay, another thing that really differentiates Konami from the bulk of the herd these days, and something Chris had alluded to as well, is your strength within the slot management systems space. So can you walk us through what sets your system Konami SYNKROS apart from your competitors out there? And whether you leverage this into other aspects of your business?
Jay Bertsch:
Yeah, I mean, the systems business has not always been, and the driving force at Konami, it's definitely something that has evolved tremendously over the last 15 or 20 years since I've been here. There was many times in conversations that systems was not a... or Konami specifically was not in the conversation when big decisions were being made around systems.
Now fast-forward, if you call it a decade later, Konami is leading the space when it comes to systems. We're very proud and humbled to say that we are the leading system out there today. And if somebody is doing a full dive into not only the tech but to the support and to the people, we're leading the way. So two things, right? I think number one is, the technology that we have and that we deploy is proven in the market. It's something that adds a tremendous amount of stability to the operation, and something that is by far, the most stable system out there. That being said, with that technology, we are innovating, and we are innovating, we think, at a much faster rate than our competitors. And then Chris highlighted a little bit of the facial recognition technology. And that's something that I think you'll see a tremendous amount of adoption as that becomes more of a standard practice within the casino side.
And as we roll out this beta customer and subsequently around the rest of the world, I think the tech will be a big reason why a lot of people select the company and the product. I think the second piece when it comes to CMSs, and it's always the adage that nobody loves the system they have, they always love the one they don't have. And I think a big part of Konami's success, not just from the tech perspective, is really been centered around the people. We've got an incredible group of people within the organization that have not only worked in casinos, but we spend a lot of time making sure that our existing customers are happy. And that we're providing a level of support to our existing customer base to make sure that, one, we're continuing to train and make sure they're able to leverage our product. But also educating them on some of the new features and the new functions that are happening.
So, for us, technology is important, but being a great provider and a great vendor for our customers is just as important to us as some of the technology that we're developing. And then there's a whole slew of products within the suite that we think we differentiate specifically when we start to get into some of the progressive functionality, bonusing and a lot of those other products as well. So we've been hyperfocused. We're one of the only products out there today that we've deployed around the world. We have one central code base, one central system that everybody utilizes, which gives us a tremendous amount of opportunity to keep every on the same version and provide that level of support that is really been the paramount with why we continue to get good references from our existing customer base. And as new customers look on, we get those referrals, which is really, really important for us.
Nick Hogan:
Absolutely. Having worked in that part of the business for over a decade, I know it's a really tough part of the business. But I'm really curious about the... I didn't get a chance to see the facial recognition, so if you could just kind of describe that to me thumbnail, how that works, what you're doing there?
Jay Bertsch:
Yeah, we probably can't share too much with respect to what the beta customer is, but we can talk about the functionality at maybe a high level. So basically what we're doing is we're embedding a camera at the point of sale, whether it's the slot machine, the table, or even from a retail perspective. And then subsequently tracking that player's rating, whether it's on the slot machine, the table, or the point of sale system. Obviously, it's a biometric tracking, meaning that we're calculating and assigning a person, call it an ID, and then subsequently associating that ID within the CMS. So it's a fairly simple exercise, but the power of being able to then track both carded and uncarded ratings for those players throughout the resort is really going to solve, we think, one of the biggest problems out there in the market today when it comes to systems, and that's that uncarded rating.
So now everybody, in a sense, becomes rated across all the retail point of sale, slots and tables. And so there's just a tremendous amount of use cases that can be applied to this technology. And we're seeing that as we have those conversations with customers today. So again, very, very simple, capturing the person's image, associating an ID to them, and then obviously just starting to track them throughout the resort.
Nick Hogan:
Okay, cool. Well, thanks for the quick overview there. So now switching over to the game side of things. And I guess this is for you, Stephanie. So my understanding is that Konami has been working really hard to rebuild, reposition its game development. So can you give us a sense of what you've been up to in this regard?
Stephanie Lau:
Of course. One of the first things anytime we rebuild or refocus ourselves is the investment in people. So we actually recently brought back Eric Schultz as our VP of studio operations. He used to work for Konami, he's been highly successful. And knowing that we wanted him to come back and kind of rebuild the Podium days, he was here back then, so he knows what it takes to be successful. And that being said, he's starting to rebuild his team. As Jay alluded before, majority of our games in the past have been coming from our KGIA studio versus our Las Vegas studio. So investing back into the studio and focusing on what works is basically what Eric will be doing. We've been taking different teams out to do more market research to understand the trends in the market, to make sure that we are hitting on the points that make games successful.
We're also partnering up with some casinos to get additional data, maybe because I'm just a data geek. But that being said, when we get to dive deeper into the player sessions and seeing how player behaviors react on what they do, how long they play at the game, how many game plays do they stay at before they move on to the next game? We can really understand why people come back or... we call them repeat visits on back to the same game. As you know, most games aren't successful, right? And so we're trying to narrow down what features make them successful. There's always a player for every single game, but how do we hit on those marks that make it the stability there, and hitting the higher marks of a one and a half, two times house index, right?
So that being said, getting to that session detail to see, hey, are the players betting up? Why are they betting up? Is it the sound, is it the graphics? So we are happy to have a lot of good partners that are willing to work with us, so that way, they can help us build better games, give us the data that we need to really refocus our studios to making what works in the market.
Nick Hogan:
Okay, cool. And yeah, indeed, this is something I think as you know, we spend a lot of time diving through these types of things as well. Really looking at behaviors and trying to understand what's getting people going and stuff. And indeed, Don can attest as well. It is endlessly fascinating because we're really trying to dive into this emotional resonance, right? Like, really what connects a game to a gambler? And man, we just have a blast doing it. So no question, I can certainly understand how it's a lot of fun for you for sure. Now, historically, one of the things that's always interested me about Konami is that you've been heavily focused on core slot products, and clearly there are a number of titles that are out there that just were absolute killers, and generated incredible returns year-after-year. Now, is this strategy one that you're continuing to follow, or are you placing increasing emphasis on the premium space?
Stephanie Lau:
I'd say kind of both. Konami's always been a company that focuses on our core product. We want to ensure that the investment that operators make into our product when they buy cabinets, that they have a fully supported game line and game development. So that would always be our biggest focus. However, obviously we'd love dipping into that premium space. Konami's always been known for this All Aboard, and maybe we just didn't have bandwidth in the past. And bringing on, again, the new blood into the studios has definitely helped us be able to expand into more of the premium space.
Like Chris mentioned before, with Lian Lian Fa, Bingo Frenzy, Buzzer and K-Pow! Pig coming out, and also a couple more really exciting titles that we've seen in the pipeline that I can't share with you at this moment in time. But we're really excited to develop in both of core and in premium. In the past, we may not have had enough dedicated resources to go really dip into premium, and now I think we're trying to get to that point where we're reinvesting back into the company and trying to get to that point. So hopefully you'll see...
But back to your original question, I don't think our strategy will pivot. We have Podium products that have been out on the floor for many, many years still doing over house, and we still want to create that content. So a big focus of ours is, again, making sure the investment that people are making into our Konami product is still our main and priority.
Nick Hogan:
Okay, cool. Well, Don, once again, I've been usurping the microphone here, so I know that you're a absolute champing at the bit with product questions, so I'll throw it to you.
Don Retzlaff:
Thanks, Nick. And Stephanie just mentioned the Podium cabinets, Jay. And Konami was always known for their well-built cabinets. I remember buying my first bank of them and showing them to the slot techs, and they looked at them and said, "Man, these are Cadillacs." Easy to access the insides and all the components when you needed to. But basically we just plugged them in, turned them on and let them run. And I was just looking at the volume of Podiums and K2V and KP3 machines that are still out there on the floor that are doing house average is astounding. But I guess the one question I have is, do you consult with anybody when you're building these games? Because it sure looked like it, because it had everything in there that the casino wanted, that my slot techs wanted.
Jay Bertsch:
Yeah, it's funny you say that about the whole Podium days, and we talk about it obviously given the success of that product. And I think like you said, I think if you look back at the Podium specifically, it is kind of the Maytag, the Cadillac of the slot machine. It's almost been a little bit of a challenge just getting them out of the field now, just because of the fact that they continue to run. And I think more importantly is that, again, it comes back to the software, and the game is actually continued to perform over the course of time. But I guess to answer your question, I think Konami, just by its inherent nature of it, the parent company, is that we used to... Well, the company was founded around basically a jukebox repair company back in the '70s, right? So I think that when it comes to being a manufacturer and providing devices out for entertainment purposes, it looks like we've got over 50 years experience in that space.
And so not only is that a leverage point for us as we develop cabinets, but it's definitely something that we build upon, and obviously take pride in. And I think Podium back in the day, was really one of the first cabinets that did some of the edge lighting, and really took the industry by storm from that standpoint. And as you see now in the market, almost every game out there has some edge lighting similar to what Podium did back in the day.
That being said, yeah, we definitely do some outside of consulting and use some firms as we develop cabinets, but there's no question that just the inherent nature of the resources that we have both here in the US, but Australia and Japan have a tremendous amount of impact, in that, just given that it's such an important trait or an important piece of just kind of who we are back to those jukebox days. But it's definitely something that as we look at the new product that will succeed Dimension, it is definitely something that we definitely look to contract with other companies. But by nature, it's been something that we've done for a long, long time.
So we're incredibly proud of the fact that you can sell a product to someone and have it still out there 15 years later, continuing to give them a return on that investment that they've had 10 times over, which is really, really important. Because as we kind of make a shift from a hardware company to a software company, the hardware is important, but at the end of the day, it really does come back to whether that software is going to run and continue to provide an awesome experience for the customer. So it's definitely something that we take pride in, and try to leverage as many not only internal resources, but external, as we roll out future hardware platforms.
Don Retzlaff:
Yeah, well, you should be incredibly proud. Those games that I first bought 20 years ago are still out there, and they're still doing well. There's thousands of them out there. I was just looking at one organization, and it's still a large percentage of their floor. And it's just because you don't have to pull them. You can stick them on the floor, and leave them there. Stephanie, your cabinets do have a long shelf life. Before they're obsoleted, what strategy is there in obsoleting cabinets? Is it only done when you can't do something on the software side, or are there other factors?
Stephanie Lau:
Well, back to what Jay was saying, we are truly trying to protect the investment of the operators. When we have to decide to obsolete a cabinet, if we have a trouble finding parts, it's something that we have to consider. The worst thing that we can do is obsolete a cabinet before the five-year depreciation or whatever depreciation schedule people have. So ideally, we want everybody to be able to trust that when you buy from Konami, you have a product like our Podiums that can withstand the test of times. We want people to know that, "Hey, we're going to protect you both on the cabinet side and the content side." So the factors really are is, if our studios can't...
I mean, majority of our content can be supported backwards. I mean, you do have to buy an upgrade part obviously because as technology evolves and changes, the memory, the platform may not be supported, so you might have to pay an upgrade charge. But we want everyone to know that, "Hey, when you buy Konami, you do have that faith and that longevity of the cabinet. Whether it be the Podiums, the Dimensions, and any other cabinet that Konami decides to create in the future."
Because obsoleting cabinets, maybe it's just me coming from the operator side, it always has a bad connotation. I'm like, "Just spent X amount of dollars for a cabinet that you guys are not going to support anymore. Great. Why did I just invest in you guys, if I..." I mean, 20, $25,000 for a cabinet, it's not cheap. The ROI on it isn't really always there. So that being said, we're always conscious of making sure that our cabinet line and what we decide to do with it is within the expectations of the people that are purchasing it.
Don Retzlaff:
Thanks, Stephanie. When you're an operator and you hear they're obsoleting a cabinet, it is one of the worst days because you-
Stephanie Lau:
Oh, yes.
Don Retzlaff:
[inaudible 00:40:25] limited. And you just know that the time's ticking on those. Luckily enough, stuff like the Podium, you can still put out there and let it run, but it's not always that easy.
Stephanie Lau:
Yeah.
Don Retzlaff:
And then talking about the pricing, I guess I'll throw this one towards Chris, since you're the new sales guy. I have an accountant that does my taxes for me. And the first year that she did them for me, I got the bill and I looked at them, and I said, "For what you do, you are not charging enough. I don't want to pay more for the service, but you are really not charging what you're delivering." And the Konami cabinets were always less than expensive than your peers. It seemed like I was buying cabinets for 12 and 15,000, where everybody else was selling them for 18 and 20,000. Is this going to continue to be a strategy for your cabinet sales going forward? Are you still going to be trying to be one of the value customers?
Chris Rowe:
Don, I think you're showing your age there with those prices. I think they bumped up just a little bit since those days. But I will say that you're not wrong. And there's been a bit of a consistent thread through the podcast today. And I think I can say this given I'm 10 weeks new to Konami at this point, but this is a company that is wildly customer-centric, wildly customer-centric. And ReelMetrics likely knows this better than we do. But yeah, I kind of liken it to the slot player who comes onto the floor with a budget. And you can take that all at once, or you can take that over time and give a better experience.
Our customers have a budget, and I'd argue that most of them don't get a budget as big as they want every year, right? And so if we can make that budget stretch a little further, then that's good for them and it's also good for us. It's good for our share, and it's good for their floors, it's good for their players. So I think value is important to us. I also think the industry has evolved, and at least I'm hearing in today's world, that there's a bit more propensity towards lease product and what that can do for keeping a floor fresh. And that's a place that suppliers like us can compete and earn outsized margins with our performance. And what a customer-centric model, when we can get in there and put on display our best products and we have to win together. So in the for-sale space, we offer value, and we want the operators to get more for their budget. And in the premium space, we'll go and prove it with performance and earn those outsized margins.
Don Retzlaff:
Most awesome. One more question for you, Chris. And that's on the product side. Seeing some really good results from the Fortune Pots and Fortune Bag series. I'm a fan of Unwooly Riches, I thought those were really fun to play. And then you mentioned the All Aboard earlier and transitioning that to a core game or for sale game. So I guess my one takeaway from all this is to the operators out there, listen, All Aboard works really, really well in high limit. So if you don't have anything in high limit and now you can buy this game and put it in high limit, put this on your 2025 checklists, just look at the numbers. They're really, really good on that.
But we're seeing different style of games now from Konami than when Konami first got into the market. It was high volatility, long, long bonus rounds with hundreds of free games. Is there any room in that portfolio now for these long, free game bonuses? Or are the recent trends with three pots and perceived persistence, is this where the bulk of the development's going in the future?
Chris Rowe:
Thanks, Don. And yeah, I mean, heritage is wildly important, isn't it? And you speak to... We've had conversation today about the Podium series and some of those classic games. You've got your China Shores, your Dragon's Law, your Mine Riches, your Xtra Xtra Rewards. And those are the games that made us great, and really found our way into this US market. I think, look, the key going forward is balance and running a balanced portfolio, and segmenting between brands, on-trend mechanics, and then pioneering mechanics to find that next hit. And I think at different stages in companies life cycles, you'll balance the portfolio differently between those categories.
But you heard Steph speak earlier to the incorporation of on-trend and how important that is. But yes, Don, there is room to stay true to our heritage, and I believe the best game designers find that inspiration, and build in those mechanics while making the games more modern and more relevant. So the short answer is yes, there is room in the portfolio for those sorts of mechanics that you speak of. And I'm super excited to see what our game of development talent can make of the great legacy brands that we have.
Don Retzlaff:
Yeah, it's always fascinating to look at the portfolio and see where the trends are, but I look at stuff like you mentioned earlier, of Lian Lian Fa, and I mentioned Bingo Frenzy, which I got to play on the floor at The Cosmopolitan. I really liked that game. And then you got to be really careful with What The Duck and it's series of games. But I just loved it. I thought the booth was really good this year. And congratulations.
Chris Rowe:
Thanks a ton.
Nick Hogan:
So guys, I see we're coming up on the hour here, so I just wanted to check in and see if you have anything, any events coming up that you'd like to promote. Are you doing ICE and things like this? Or what's on the agenda here over the next couple of months?
Jay Bertsch:
Yeah, next big show for us, obviously ICE in Barcelona, a whole group of us will be there. Obviously love to connect with people while we're over there. I think it's the, what is it, 19th, 20th, 21st, 22nd of January? That's the next big one for us. And then from there, we obviously go on to AIGA. So lots of a couple big shows coming up. But to be honest, we're just kind of getting through G2E and all the pre-G2Es and all that. So we're finally all starting to feel like we're getting our feet back under us. But no, definitely looking forward to ICE on the fresh start of a new year.
Nick Hogan:
Okay, cool. Don, did you have any more questions for the team there?
Don Retzlaff:
I think I'm good, Nick.
Nick Hogan:
Okay. Well, very good. Just thanks again, guys, for your time today. It was really lovely to speak with all you. There are a lot of exciting things happening, and we of course wish you the absolute best of luck in everything. And yeah, we'll look forward to seeing you on the floor in Barcelona, which is going to be a new experience for all of us. So really look forward to that.
Jay Bertsch:
Everybody seems very excited about it. And thanks to you guys as well, we appreciate the opportunity to talk a little bit about what we're doing, and obviously continue to work with you guys on the ReelMetrics suite. So, appreciate the opportunity, and best success to you guys as well.
Nick Hogan:
Okay, thank you so much guys. Thanks for your time. We'll see ya.
Jay Bertsch:
See ya.
Nick Hogan:
All right.
`,

};

export default S03E10;
